<template>
	<div>
		<Button class="button" @click="redirect('clients')">
			Clients
		</button>

		<Button class="button" @click="redirect('groups')">
			Groups
		</button>

		<Button class="button" @click="redirect('users')">
			Users
		</button>
	</div>
</template>

<script>
import Button from "@/components/Button";

export default {
	name: "Admin",

	components: {
		Button
	},

	methods: {
		redirect (location) {
			this.$router.push({
				path: `/admin/${location}`
			});
		}
	}
};
</script>

<style scoped>
.button {
	margin-top: 15px;
}
</style>
